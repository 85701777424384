import Form from '@/components/form';
import Loading from '@/components/loading';
import { useGraphQL } from '@/data';
import type { DeepPartial } from '@/types/deepPartial';
import { Query } from '@/types/schema';
import { lastFormAtom, lastPayloadAtom } from '@/utils/atoms';
import type { FormikConfig, FormikHelpers } from 'formik';
import type { DocumentNode } from 'graphql';
import { useSetAtom } from 'jotai';
import { isEmpty } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useEffectOnceWhen } from 'rooks';
import { GraphqlContext } from './graphqlProvider';

export default function FormGraphqlProvider<T = any>( {
	id,
	initialValues,
	onSubmit = async () => {},
	queryKey,
	query,
	subscription,
	select,
	getApiPayload,
	autoSaveKey,
	onSuccess,
	...props
}: {
	id?: string,
	initialValues?: () => DeepPartial<T>,
	autoSaveKey?: string,
	queryKey: string | Array<string>,
	query: DocumentNode,
	subscription?: Record<string, string>,
	select?: ( data: T ) => any,
	onSuccess?: ( data: T ) => void,
	getApiPayload?: ( values: T ) => any,
	onSubmit?: ( values: T, formikHelpers: FormikHelpers<T> & { initialValues: T } ) => Promise<T | void>
} & Omit<FormikConfig<T>, 'initialValues' | 'onSubmit'> ) {
	const setLastForm = useSetAtom( lastFormAtom );
	const setLastPayload = useSetAtom( lastPayloadAtom );
	const { data: item, isFetching } = useGraphQL<any, T>( {
		queryKey,
		query,
		variables: { id },
		subscription,
	}, {
		enabled: Boolean( id ),
		select : ( data ) => {
			if ( isEmpty( data ) ) return data;
			const item: T = Object.values( data as object )[ 0 ];
			return select ? select( item ) : item;
		},
		onSuccess,
	} );
	
	const initValues = useMemo<T>( () => {
		if ( !id ) return initialValues?.() as T || {} as T;
		if ( !item ) return undefined as T;
		return item;
	}, [ item ] );
	
	useEffectOnceWhen( () => {
		setLastForm( { [ autoSaveKey! ]: initValues } );
		if ( getApiPayload ) setLastPayload( { [ autoSaveKey! ]: getApiPayload( initValues! ) } );
	}, Boolean( autoSaveKey ) && !isEmpty( initValues ) );
	
	// cleanup last saved on unmount
	useEffect( () => () => {
		if ( !autoSaveKey ) return;
		setLastForm( { [ autoSaveKey ]: null } );
		setLastPayload( { [ autoSaveKey ]: null } );
	}, [] );
	
	// if ( isLoading || enabled && isPlaceholderData ) return <Loading/>;
	const [ firstFetching, setFirstFetching ] = useState( true );
	
	useEffect( () => {
		if ( !isFetching ) setFirstFetching( false );
	}, [ isFetching ] );
	
	if ( firstFetching ) return <Loading/>;
	
	return (
		<GraphqlContext.Provider value={item}>
			<Form
				initialValues={initValues}
				onSubmit={async ( values, formikHelpers ) => {
					if ( !values ) return;
					await onSubmit( values, { ...formikHelpers, initialValues: initValues } );
					if ( autoSaveKey ) {
						setLastForm( { [ autoSaveKey ]: values } );
					}
				}}
				{...props}
			/>
		</GraphqlContext.Provider>
	);
}

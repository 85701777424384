import LinkModal from '@/components/tiptap/linkModal';
import { useMenu } from '@/providers/menu';
import { useModal } from '@/providers/modal'; // Assuming the modal provider is here
import {
	CodeRounded as CodeIcon,
	FormatAlignCenterRounded as FormatAlignCenterRoundedIcon,
	FormatAlignLeftRounded as FormatAlignLeftRoundedIcon,
	FormatAlignRightRounded as FormatAlignRightRoundedIcon,
	FormatBoldRounded as FormatBoldRoundedIcon,
	FormatItalicRounded as FormatItalicRoundedIcon,
	FormatListBulletedRounded as FormatListBulletedRoundedIcon,
	FormatListNumberedRounded as FormatListNumberedRoundedIcon,
	FormatQuoteRounded as BlockQuoteIcon,
	FormatUnderlinedRounded as FormatUnderlinedRoundedIcon,
	HorizontalRule as HorizontalRuleIcon,
	LinkRounded as LinkIcon,
	MoreHoriz as MoreHorizIcon,
	RedoRounded as RedoRoundedIcon,
	StrikethroughSRounded as StrikethroughSRoundedIcon,
	TitleRounded as TitleIcon,
	UndoRounded as UndoRoundedIcon,
} from '@mui/icons-material';
import { Button, Divider, ListItemIcon, MenuItem, Paper, Stack, Tooltip } from '@mui/material';
import { Fragment } from 'react';

export default function TiptapToolbar( {
	editor,
	toolBarMaxToShow = 100,
}: {
	editor: any,
	toolBarMaxToShow?: number
} ) {
	const { showMenu } = useMenu();
	const { showModal } = useModal();
	
	if ( !editor ) return null;
	
	const buttons = [
		{
			icon    : <FormatBoldRoundedIcon sx={{ fontSize: 21 }}/>,
			name    : 'Bold',
			tooltip : 'Bold',
			onClick : () => editor.chain().focus().toggleBold().run(),
			isActive: editor.isActive( 'bold' ),
		},
		{
			icon    : <FormatItalicRoundedIcon sx={{ fontSize: 21 }}/>,
			name    : 'Italic',
			tooltip : 'Italic',
			onClick : () => editor.chain().focus().toggleItalic().run(),
			isActive: editor.isActive( 'italic' ),
		},
		{
			icon    : <StrikethroughSRoundedIcon sx={{ fontSize: 21 }}/>,
			name    : 'Strikethrough',
			tooltip : 'Strikethrough',
			onClick : () => editor.chain().focus().toggleStrike().run(),
			isActive: editor.isActive( 'strike' ),
		},
		{
			icon    : <FormatUnderlinedRoundedIcon sx={{ fontSize: 21 }}/>,
			name    : 'Underline',
			tooltip : 'Underline',
			onClick : () => editor.chain().focus().toggleUnderline().run(),
			isActive: editor.isActive( 'underline' ),
		},
		{
			icon    : <TitleIcon sx={{ fontSize: 21 }}/>,
			name    : 'Heading 1',
			tooltip : 'Heading 1',
			onClick : () => editor.chain().focus().toggleHeading( { level: 1 } ).run(),
			isActive: editor.isActive( 'heading', { level: 1 } ),
		},
		{
			icon    : <TitleIcon sx={{ fontSize: 18 }}/>,
			name    : 'Heading 2',
			tooltip : 'Heading 2',
			onClick : () => editor.chain().focus().toggleHeading( { level: 2 } ).run(),
			isActive: editor.isActive( 'heading', { level: 2 } ),
		},
		{
			icon   : <LinkIcon sx={{ fontSize: 21 }}/>,
			name   : 'Link',
			tooltip: 'Insert Link',
			onClick: () => showModal( LinkModal, { maxWidth: 'xs' }, {
				editor,
				link: editor.getAttributes( 'link' ).href,
				text: editor.state.doc.nodeAt( editor.state.selection.from )?.textContent,
			} ),
			isActive: editor.isActive( 'link' ),
		},
		{
			icon    : <FormatListNumberedRoundedIcon sx={{ fontSize: 21 }}/>,
			name    : 'Ordered List',
			tooltip : 'Ordered List',
			onClick : () => editor.chain().focus().toggleOrderedList().run(),
			isActive: editor.isActive( 'orderedList' ),
		},
		{
			icon    : <FormatListBulletedRoundedIcon sx={{ fontSize: 21 }}/>,
			name    : 'Bullet List',
			tooltip : 'Bullet List',
			onClick : () => editor.chain().focus().toggleBulletList().run(),
			isActive: editor.isActive( 'bulletList' ),
		},
		{
			icon    : <BlockQuoteIcon sx={{ fontSize: 21 }}/>,
			name    : 'Block Quote',
			tooltip : 'Block Quote',
			onClick : () => editor.chain().focus().toggleBlockquote().run(),
			isActive: editor.isActive( 'blockquote' ),
		},
		{
			icon    : <CodeIcon sx={{ fontSize: 21 }}/>,
			name    : 'Code',
			tooltip : 'Code',
			onClick : () => editor.chain().focus().toggleCode().run(),
			isActive: editor.isActive( 'code' ),
		},
		{
			icon    : <FormatAlignLeftRoundedIcon sx={{ fontSize: 21 }}/>,
			name    : 'Align Left',
			tooltip : 'Align Left',
			onClick : () => editor.chain().focus().setTextAlign( 'left' ).run(),
			isActive: editor.isActive( { textAlign: 'left' } ),
		},
		{
			icon    : <FormatAlignCenterRoundedIcon sx={{ fontSize: 21 }}/>,
			name    : 'Align Center',
			tooltip : 'Align Center',
			onClick : () => editor.chain().focus().setTextAlign( 'center' ).run(),
			isActive: editor.isActive( { textAlign: 'center' } ),
		},
		{
			icon    : <FormatAlignRightRoundedIcon sx={{ fontSize: 21 }}/>,
			name    : 'Align Right',
			tooltip : 'Align Right',
			onClick : () => editor.chain().focus().setTextAlign( 'right' ).run(),
			isActive: editor.isActive( { textAlign: 'right' } ),
		},
		{
			icon   : <HorizontalRuleIcon sx={{ fontSize: 21 }}/>,
			name   : 'Horizontal Rule',
			tooltip: 'Insert Horizontal Line',
			onClick: () => editor.chain().focus().setHorizontalRule().run(),
		},
		{
			icon   : <UndoRoundedIcon sx={{ fontSize: 21 }}/>,
			name   : 'Undo',
			tooltip: 'Undo',
			onClick: () => editor.commands.undo(),
		},
		{
			icon   : <RedoRoundedIcon sx={{ fontSize: 21 }}/>,
			name   : 'Redo',
			tooltip: 'Redo',
			onClick: () => editor.commands.redo(),
		},
	];
	
	// Split the buttons array into visible buttons and those in the menu
	const visibleButtons = buttons.slice( 0, toolBarMaxToShow );
	const menuButtons = buttons.slice( toolBarMaxToShow );
	
	return (
		<Paper sx={{ bgcolor: 'background.default', width: 'fit-content' }}>
			<Stack direction='row' spacing={1} alignItems='center' p={.2} sx={{ flexWrap: 'wrap' }}>
				{visibleButtons.map( ( button, index ) => (
					<Fragment key={index}>
						<Tooltip title={button.tooltip}>
							<Button
								variant='text'
								sx={{
									bgcolor: button.isActive ? 'divider' : 'transparent',
								}}
								onClick={button.onClick}>
								{button.icon}
							</Button>
						</Tooltip>
						{/* Add dividers only in the specified places */}
						{[ 'Underline',
						   'Heading 2',
						   'Link',
						   'Bullet List',
						   'Block Quote',
						   'Code',
						   'Align Right',
						   'Horizontal Rule',
						].includes( button.name ) && (
							<Divider flexItem orientation='vertical' variant='middle'/>
						)}
					</Fragment>
				) )}
				{menuButtons.length > 0 && (
					<Button
						variant='text'
						onClick={( e: React.MouseEvent<HTMLButtonElement> ) => showMenu( ( { closeMenu } ) => (
							<Fragment>
								{menuButtons.map( ( button, index ) => (
									<MenuItem
										key={index}
										onClick={() => {
											button.onClick();
											closeMenu();
										}}>
										<ListItemIcon>
											{button.icon}
										</ListItemIcon>
										{button.name}
									</MenuItem>
								) )}
							</Fragment>
						), e.currentTarget )}>
						<MoreHorizIcon/>
					</Button>
				)}
			</Stack>
		</Paper>
	);
}

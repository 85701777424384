import { ModalFormWrapper } from '@/components/form/modal';
import PageLinkComponent from '@/components/page/linkComponent';
import PageSection from '@/components/page/section';
import TipTapTypography from '@/components/tiptap/readOnlyTipTap';
import { useModal } from '@/providers/modal';
import { AddCircle as AddCircleIcon, Security as SecurityIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import PolicyInputModal from '../../../../modals/policy';

export default function PolicySection() {
	const formik = useFormikContext<any>();
	const { t } = useTranslation();
	const { showModal } = useModal();
	
	return (
		<PageSection
			primary={t( 'common:policy' )}
			secondary={t( 'common:assign-policy' )}
			actions={[
				!process.env.NEXT_PUBLIC_IS_CLOVER && formik.values.policy && {
					name       : t( 'common:view-policy' ),
					buttonProps: { variant: 'outlined', startIcon: <SecurityIcon/> },
					props      : {
						component: PageLinkComponent,
						href     : `/p/${formik.values.policy?.id}/policy`,
						target   : '_blank',
					},
				}, {
					name       : t( 'common:type-policy' ),
					buttonProps: {
						variant  : 'contained',
						startIcon: <AddCircleIcon/>,
					},
					onClick: () => showModal( PolicyInputModal, undefined, {
						id      : formik.values.policy?.id,
						Wrapper : ModalFormWrapper,
						onSubmit: ( policy ) => formik.setFieldValue( 'policy', policy ),
					} ),
				},
			]}>
			{formik.values.policy?.policy ? (
				<TipTapTypography sx={{ maxHeight: 500 }}>
					{formik.values.policy?.policy}
				</TipTapTypography>
			) : (
				<Typography textAlign='center' p={2} color='text.secondary'>
					{t( 'common:no-policy' )}
				</Typography>
			)}
		</PageSection>
	);
}

import FormAddress, { useAddressValidationSchema } from '@/components/form/fields/address';
import PageSection from '@/components/page/section';
import { mutateGraphQL } from '@/data/apollo';
import { LocationRead, LocationWrite } from '@/data/management/location.graphql';
import FormGraphqlProvider from '@/data/query/formGraphqlProvider';
import TimezoneSelect from '@/pages/formSelects/timezoneSelect';
import { CheckPermissions, permissions } from '@/providers/auth/usePermissions';
import type { Location, MutationLocationWriteArgs } from '@/types/schema';
import { Box, ButtonProps } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { pick } from 'lodash-es';
import type { ComponentType, ReactNode } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PolicySection from '../../../commerce/form/policy';
import GatewayComponent from './gateway';

export default function LocationForm( {
	id,
	onSubmit,
	Wrapper = Fragment,
	disabledIfNoLat = false,
	disabledIfNoGateway = false,
}: {
	id?: string,
	onSubmit?: ( location: Location ) => void,
	Wrapper?: ComponentType<{ name: string, children: ReactNode, saveButtonProps?: ButtonProps<'button', object> }>,
	disabledIfNoLat?: boolean,
	disabledIfNoGateway?: boolean
} ) {
	const queryClient = useQueryClient();
	const validationSchema = useAddressValidationSchema( 'address' );
	const { t } = useTranslation();
	
	return (
		<CheckPermissions redirect='/dashboard/management/locations' permissions={permissions.locations.write}>
			<FormGraphqlProvider<Location>
				id={id}
				queryKey='location'
				query={LocationRead}
				initialValues={() => ( { timezone: Intl.DateTimeFormat?.().resolvedOptions().timeZone } )}
				validationSchema={validationSchema}
				onSubmit={async ( { id, ...values } ) => {
					try {
						const { locationWrite } = await mutateGraphQL<MutationLocationWriteArgs>( {
							mutation : LocationWrite,
							variables: {
								id,
								method: id ? 'Saved Changes' : 'New Location',
								input : {
									name    : values.name || values.address.line1,
									timezone: values.timezone,
									address : {
										...pick( values.address, [
											'id',
											'line1',
											'line2',
											'city',
											'postalCode',
											'state',
											'country',
										] ),
										lat: +values.address?.lat || null,
										lng: +values.address?.lng || null,
									},
									policy : values.policy?.id || null,
									gateway: values.gateway?.id ? values.gateway.id : null,
								},
							},
						} );
						onSubmit?.( locationWrite );
						await queryClient.invalidateQueries( [ 'company' ] );
					} catch ( e ) {
						throw e;
					}
				}}>
				{( { values } ) => (
					<Wrapper
						name={t( 'common:location' )}
						saveButtonProps={{
							disabled:
								disabledIfNoLat && !Boolean( values.address?.lat )
								|| disabledIfNoGateway && ( !values.gateway || ( values.gateway as any ) === 'None' ),
						}}>
						<PageSection primary='Details'>
							<Box>
								<FormAddress showFriendlyName name='address'/>
							</Box>
						</PageSection>
						<PageSection primary='Timezone'>
							<TimezoneSelect name='timezone' textFieldProps={{ size: 'small' }}/>
						</PageSection>
						<GatewayComponent/>
						<PolicySection/>
					</Wrapper>
				)}
			</FormGraphqlProvider>
		</CheckPermissions>
	);
}

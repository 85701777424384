import CallEffect from '@/components/callEffect';
import Loading from '@/components/loading';
import { useGraphQL } from '@/data';
import useEventEffect from '@/hooks/useEventEffect';
import ErrorPage from '@/pages/_error.page';
import { useEvents } from '@/providers/event';
import type { Query } from '@/types/schema';
import { Typography } from '@mui/material';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { DocumentNode } from 'graphql';
import { isEmpty, isNil } from 'lodash-es';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

export const GraphqlContext = createContext<any>( null );
GraphqlContext.displayName = 'Graphql';

export type GraphqlProviderProps<S = any, T = any> = {
	queryKey: string | string[],
	query: DocumentNode,
	variables?: T,
	subscription?: Record<string, string>,
	initialLoad?: ( data: S ) => void,
	hideLoading?: boolean,
	loader?: ReactNode,
	children: ( ( data: S ) => ReactNode ) | ReactNode,
	options?: Omit<UseQueryOptions<Query>, 'initialData'> & { initialData?: () => undefined }
};

export default function GraphqlProvider<S = any, T = any>( {
	options,
	initialLoad,
	children,
	hideLoading,
	loader,
	...props
}: GraphqlProviderProps<S, T> ) {
	const event = useEvents();
	const { data, error, isFetching, refetch } = useGraphQL( props as any, options );
	const [ firstFetching, setFirstFetching ] = useState( true );
	
	useEventEffect( event, 'reload.singleQuery', async ( refetchQuery ) => {
		if ( refetchQuery ) await refetch();
	}, [],
	);
	
	useEffect( () => {
		if ( !isFetching ) setFirstFetching( false );
	}, [ isFetching ] );
	
	if ( error ) {
		return (
			<ErrorPage
				title='An Error Occurred'
				subtitle='Could not fetch specified resource'
			/>
		);
	}
	
	if ( !hideLoading && firstFetching || !data ) return <Loading/>;
	if ( !firstFetching && isEmpty( data ) && loader ) return loader;
	if ( !Object.keys( data! ).length && ( !options || options?.enabled ) ) return null;
	
	const result = Object.values( data )[ 0 ] as S;
	
	if ( isNil( result ) && ( !options || options?.enabled ) ) return (
		<Typography textAlign='center'>
			Error Data not Found
		</Typography>
	);
	
	return (
		<GraphqlContext.Provider value={result}>
			<CallEffect
				func={() => {
					if ( Object.keys( data ).length && initialLoad ) initialLoad( result );
				}}
				deps={[ result ]}
			/>
			{typeof children === 'function' ? children( result ) : children}
		</GraphqlContext.Provider>
	);
}

export function useGraphqlResult<T>() {
	return useContext( GraphqlContext ) as T;
}
